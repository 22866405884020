/* eslint-disable react/jsx-indent */
import React from 'react';
import { Col, Row } from 'reactstrap';

import './sameDay.scss';

const SameDay = () => (
    <div className="same-day-container font-syncopate">
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06152109/mystylebannera.jpg"
            className="img-header"
            alt="Crafting custom mens rings out of exotic materials since 2000. Made to order in the USA.
          We supply to customers throughout the US and Canada"
        />
        <div className="wrapper">
            <h1 className="text-center heading">
                Over 40,000<br />custom precious metal bands<br />shipped the same day.
            </h1>
        </div>
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06145747/goldline.jpg"
            alt="gold-line"
            width="100%"
            className="img-center"
        />
        <div className="wrapper">
            <h1 className="subtitle text-center">GUARANTEED SAME-DAY SHIPPING*</h1>
            <Row>
                <Col sm={6}>
                    <p className="font-open-sans-condensed description pr-3">
                        Order a qualifying precious metal band by 2 PM EST, we’ll handcraft it and ship it the same day.
                        Every band is still made to order and guaranteed for life.
                        <br />
                        <br />
                        10K, 14K, & 18K White, Yellow, & Rose Gold, & Platinum
                        <br />
                        <br />
                        Any profile, finish, width, and weight combination from the My Style Collection
                        <br />
                        <br />
                        Any size up to 13.5
                        <br />
                        <br />
                        Even Inside engravings
                        <br />
                        <br />
                        *Starting 10/01/20 Lashbrook guarantees same-day shipping on qualifying precious metal
                        purchases. If for any reason we are unable to ship the same day we will cover the cost to
                        overnight ship your order. Orders which do ship out the same day as expected will ship via the
                        method you select, and the shipping cost is covered by you.
                    </p>
                </Col>
                <Col>
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06143815/threerings-min.png"
                        alt="threerings"
                    />
                </Col>
            </Row>
        </div>
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06145747/goldline.jpg"
            alt="gold-line"
            className="img-center"
        />
        <div className="wrapper">
            <h1 className="subtitle text-center">THE MY STYLE COLLECTION*</h1>
            <Row>
                <Col sm={6}>
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06143501/mystyle-min.png"
                        alt="threerings"
                    />
                </Col>
                <Col sm={6}>
                    <p>
                        Show the endless possibilities of custom in 60 seconds with the My Style Collection. Your
                        customers can easily mix-and-match aspects of various rings including metal, profile, width,
                        weight, finish, and engraving.
                        <br />
                        <br />
                        Need a My Style?<br />
                        888-252-7388<br />
                        info@lashbrookdesigns.com
                    </p>
                </Col>
            </Row>
        </div>
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06145747/goldline.jpg"
            alt="gold-line"
            className="img-center"
        />
        <div className="wrapper">
            <h1 className="subtitle text-center">LIVING OUR MISSION</h1>
            <Row>
                <Col sm={6}>
                    <p className="font-open-sans-condensed description pr-3">
                        Rings with meaning.<br />Rings that tell a story.<br />Rings that make a difference.
                        <br />
                        <br />
                        Lashbrook is the founding sponsor of Mercury Free Mining, whose mission is to offer artisanal
                        small-scale miners an alternative to using mercury, ending the cycle of suffering this heavy
                        metal continues to cause in the lives of millions.
                    </p>
                </Col>
                <Col>
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06142551/MFM.jpg"
                        alt="threerings"
                    />
                </Col>
            </Row>
        </div>
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06145747/goldline.jpg"
            alt="gold-line"
            className="img-center"
        />
        <div className="wrapper">
            <h1 className="subtitle text-center">20 YEARS OF CUSTOM</h1>
            <Row>
                <Col>
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/07/07085557/PRODUCTION_IMAGES-0040.jpg"
                        alt="PRODUCTION_IMAGES"
                    />
                </Col>
                <Col sm={6}>
                    <p className="font-open-sans-condensed description pr-3">
                        For over 20 years, our artisans have handcrafted every ring, one at a time, to fit the
                        uniqueness of each person we make them for. Starting as a solid block of metal, we use only the
                        highest quality materials. Our bands with same-day shipping are made from the same high-quality
                        material we use in every precious metal band. As always, every Lashbrook band is guaranteed for
                        life.
                    </p>
                </Col>
            </Row>
        </div>
    </div>
);

export default SameDay;
