import clsx from 'clsx';
import React from 'react';
import { ITabs } from '../../types/customizer';
import listIcon from '../../../../assets/images/list-icon.svg';
import rightArrowIcon from '../../../../assets/images/right-arrow.svg';
import closeIcon from '../../../../assets/images/close-icon.svg';

interface Props {
    pages: ITabs;
    selectedTab: string;
    handleActiveTab: Function;
    toggleOpen: React.MouseEventHandler<HTMLDivElement>;
    open: boolean;
}

const MobileSidebar = ({
    pages,
    selectedTab,
    handleActiveTab,
    toggleOpen,
    open,
}: Props) => ( // Hide for sm devices
    <div className="sm:tw-hidden">
        {!open &&
            <div role="presentation" className="tw-absolute tw-left-3 tw-top-4 tw-cursor-pointer tw-flex" onClick={toggleOpen}>
                <i className="fa-solid fa-ellipsis-vertical tw-pr-0.5 tw-text-blue-900 " />
                <i className="fa-solid fa-bars tw-text-blue-900" />
                <span className="tw-text-base tw-font-bold tw-ml-3 tw-hidden md:tw-inline-block">All Steps</span>
            </div>}
        {open && (
            <div className="tw-bg-white tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-border-t tw-border-gray-500">
                {pages.map(item => (
                    <div role="presentation" key={item?.eventKey} className="tw-text-center md:tw-text-base tw-font-semibold tw-cursor-pointer tw-py-3 tw-border-solid tw-border-b tw-border-gray-500" onClick={handleActiveTab(item.eventKey, true)}>
                        <span className={clsx('tw-relative sm:tw-w-64 tw-w-52 tw-inline-block primary-font-family', { 'tw-underline': selectedTab === item.eventKey })}>
                            {item.eventKey === selectedTab && <i className="fa-solid fa-arrow-right tw-text-blue-900 tw-absolute tw--left-8 tw-top-1" />}
                            {typeof item.title === 'string' && item.title}
                            {typeof item.title === 'function' && item.title()}
                        </span>
                    </div>))
                }
                <div role="presentation" className="tw-absolute tw-left-3 tw-top-3 tw-cursor-pointer" onClick={toggleOpen}>
                    <img src={closeIcon} alt="list_icon" className="tw-inline" />
                </div>
            </div>)}
    </div>
);

export default MobileSidebar;
