/* eslint-disable camelcase */
import React, { ReactNode } from 'react';
import SwatchFilter from '../components/SwatchFilter';
import NoneTabFilter from '../components/NoneTabFilter';
import { ISwatchContent, ITab, ITabs } from '../types/customizer';
import { getFilterMetaData, getSelectedOption, getWarning } from './commonRingUtils';

export const getOutsideFeatureItems = (product: any) => {
    const group = product.feature_groups?.[0];
    if (!group) return {};

    const { feature_group = [] } = group;
    const SKU_LIST: string[] = feature_group.map((item: any) => item.sku);
    const selectedSKU = feature_group.find((item: any) => item.selected);

    const obj: {isEnabled?: boolean, content?: ISwatchContent, items?: ITab[], selectedFeature?:string, component?: React.ComponentType<any>; } = {
        content: undefined,
        items: undefined,
    };

    if (SKU_LIST.includes('N') && selectedSKU?.sku === 'N') {
        obj.component = NoneTabFilter;
        obj.isEnabled = true;
        obj.content = {
            hasSwatch: true,
            fieldType: 'feature_group',
            options: feature_group,
            selectedOption: getSelectedOption(feature_group),
        };
    }
    if (SKU_LIST.includes('G') && selectedSKU?.sku === 'G') {
        obj.items = getInlayItems(product, group);
        obj.selectedFeature = 'Inlay';
    }
    if (SKU_LIST.includes('M') && selectedSKU?.sku === 'M') {
        obj.items = getDesignPatternItems(product, group);
        obj.selectedFeature = 'Design Pattern';
    }
    if (SKU_LIST.includes('D') && selectedSKU?.sku === 'D') {
        obj.items = getGemstoneItems(product, group);
        obj.selectedFeature = 'Gemstone';
    }
    if (SKU_LIST.includes('A') && selectedSKU?.sku === 'A') {
        obj.items = getCerokoteItems(product, group);
        obj.selectedFeature = 'All Cerakote';
    }
    return obj;
};


const getDesignPatternItems = (product: any, group: any) => {
    const items: ITabs = [
        {
            title: (isActive) => isActive ? <i className="fa-solid fa-circle-xmark tw-text-gray-400"></i> : <i className="fa-solid fa-circle-xmark tw-text-blue-900"></i>,
            label: 'None Tab',
            eventKey: 'none',
            actionPayload: { feature_group: 'N' },
            isEnabled: true,
            component: () => null
        },
        {
            title: 'Pattern',
            label: 'Design Pattern',
            eventKey: 'pattern',
            component: SwatchFilter,
            isEnabled: group.machine_pattern?.length > 0,
            isSelected: true,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'machine_pattern'),
                // warning: getWarning(product, 'machine_pattern'),
                hasSwatch: true,
                fieldType: 'machine_pattern',
                options: group.machine_pattern || [],
                selectedOption: getSelectedOption(group.machine_pattern),
            },
        },
        {
            title: 'Accent',
            label: 'Accent Color',
            eventKey: 'accent',
            component: SwatchFilter,
            isEnabled: group.machine_pattern_option?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'machine_pattern_option'),
                // warning: getWarning(product, 'machine_pattern_option'),
                hasSwatch: true,
                fieldType: 'machine_pattern_option',
                options: group.machine_pattern_option || [],
                selectedOption: getSelectedOption(group.machine_pattern_option),
            },
        },
        {
            title: 'Signet Engraving',
            label: 'Signet Engraving',
            eventKey: 'signet_engraving',
            component: SwatchFilter,
            isEnabled: group.signet_engraving?.length > 0,
            isSelected: true,
            content: {
                hasSwatch: true,
                fieldType: 'signet_engraving',
                options: group.signet_engraving || [],
                selectedOption: getSelectedOption(group.signet_engraving),
            },
        },
        {
            title: 'Color',
            label: 'Cerakote Color',
            eventKey: 'cerakote_color',
            component: SwatchFilter,
            isEnabled: group.cerakote_color?.length > 0,
            isSelected: true,
            content: {
                hasSwatch: true,
                fieldType: 'cerakote_color',
                options: group.cerakote_color || [],
                selectedOption: getSelectedOption(group.cerakote_color),
            },
        },
    ];
    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};

const getGemstoneItems = (product: any, group: any) => {
    const items: ITabs = [
        {
            title: (isActive) => isActive ? <i className="fa-solid fa-circle-xmark tw-text-gray-400"></i> : <i className="fa-solid fa-circle-xmark tw-text-blue-900"></i>,
            label: 'None Tab',
            eventKey: 'none',
            actionPayload: { feature_group: 'N' },
            isEnabled: true,
            component: () => null
        },
        {
            title: 'Arrangement',
            label: 'Gemstone Arrangement',
            eventKey: 'arrangement',
            component: SwatchFilter,
            isEnabled: group.gemstone_arrangement?.length > 0,
            isSelected: true,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_arrangement'),
                // warning: getWarning(product, 'gemstone_arrangement'),
                hasSwatch: true,
                fieldType: 'gemstone_arrangement',
                options: group.gemstone_arrangement || [],
                selectedOption: getSelectedOption(group.gemstone_arrangement),
            },
        },
        {
            title: 'Color',
            label: 'Gemstone Color',
            eventKey: 'color',
            component: SwatchFilter,
            isEnabled: group.gemstone_material?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_material'),
                // warning: getWarning(product, 'gemstone_material'),
                hasSwatch: true,
                fieldType: 'gemstone_material',
                options: group.gemstone_material || [],
                selectedOption: getSelectedOption(group.gemstone_material),
            },
        },
        {
            title: 'Shape',
            label: 'Gemstone Shape',
            eventKey: 'shape',
            component: SwatchFilter,
            isEnabled: group.gemstone_cut?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_cut'),
                // warning: getWarning(product, 'gemstone_cut'),
                hasSwatch: true,
                fieldType: 'gemstone_cut',
                options: group.gemstone_cut || [],
                selectedOption: getSelectedOption(group.gemstone_cut),
            },
        },
        {
            title: 'Size',
            label: 'Gemstone Size',
            eventKey: 'size',
            component: SwatchFilter,
            isEnabled: group.gemstone_size?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_size'),
                // warning: getWarning(product, 'gemstone_size'),
                hasSwatch: false,
                fieldType: 'gemstone_size',
                options: group.gemstone_size || [],
                selectedOption: getSelectedOption(group.gemstone_size),
            },
        },
        {
            title: 'Setting',
            label: 'Gemstone Setting Style',
            eventKey: 'setting',
            component: SwatchFilter,
            isEnabled: group.gemstone_setting?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_setting'),
                // warning: getWarning(product, 'gemstone_setting'),
                hasSwatch: false,
                fieldType: 'gemstone_setting',
                options: group.gemstone_setting || [],
                selectedOption: getSelectedOption(group.gemstone_setting),
            },
        },
    ];
    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};

const getInlayItems = (product: any, group: any) => {
    const items: ITabs = [
        {
            title: (isActive) => isActive ? <i className="fa-solid fa-circle-xmark tw-text-gray-400"></i> : <i className="fa-solid fa-circle-xmark tw-text-blue-900"></i>,
            label: 'None Tab',
            eventKey: 'none',
            actionPayload: { feature_group: 'N' },
            isEnabled: true,
            component: () => null
        },
        {
            title: 'Placement',
            label: 'Inlay Placement',
            eventKey: 'placement',
            component: SwatchFilter,
            isEnabled: group.groove_style?.length > 0,
            isSelected: true,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'groove_style'),
                // warning: getWarning(product, 'groove_style'),
                hasSwatch: false,
                fieldType: 'groove_style',
                options: group.groove_style || [],
                selectedOption: getSelectedOption(group.groove_style),
            },
        },
        {
            title: 'Width',
            label: 'Inlay Style',
            eventKey: 'inlay_width',
            component: SwatchFilter,
            isEnabled: group.groove_group?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'groove_group'),
                // warning: getWarning(product, 'groove_group'),
                hasSwatch: false,
                fieldType: 'groove_group',
                options: group.groove_group || [],
                selectedOption: getSelectedOption(group.groove_group),
            },
        },
        {
            title: 'Inlay',
            label: 'Signet Inlay',
            eventKey: 'signet_inlay',
            component: SwatchFilter,
            isEnabled: group.inlay?.inlay?.length > 0,
            isSelected: true,
            content: {
                hasSwatch: true,
                fieldType: 'signet_inlay',
                options: group.inlay?.inlay || [],
                selectedOption: getSelectedOption(group.inlay?.inlay),
            },
        },
        {
            title: 'Inlay Finish',
            label: 'Signet Inlay Finish',
            eventKey: 'signet_inlayfinish',
            component: SwatchFilter,
            isEnabled: group.inlay?.inlayFinish?.length > 0,
            isSelected: true,
            content: {
                hasSwatch: true,
                fieldType: 'signet_inlayfinish',
                options: group.inlay?.inlayFinish || [],
                selectedOption: getSelectedOption(group.inlay?.inlayFinish),
            },
        },
    ];

    const grooves = group.grooves || [];
    grooves.forEach((groove: any, i: any) => {
        items.push({
            title: 'Material',
            label: 'Inlay Material',
            eventKey: `material${i}`,
            component: SwatchFilter,
            isEnabled: groove.inlay?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'grooves'),
                // warning: getWarning(product, `inlay_${i}`),
                hasSwatch: true,
                fieldType: `inlay:${i}:${groove.sku}`,
                options: groove.inlay || [],
                selectedOption: getSelectedOption(groove.inlay),
            },
        });

        items.push({
            title: 'Finish',
            label: groove.inlay_finish.name,
            eventKey: `finish${i}`,
            component: SwatchFilter,
            isEnabled: groove.inlay_finish?.items?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'grooves'),
                // warning: getWarning(product, `inlay_finish_${i}`),
                hasSwatch: true,
                fieldType: `inlay_finish:${i}:${groove.sku}`,
                options: groove.inlay_finish?.items || [],
                selectedOption: getSelectedOption(groove.inlay_finish.items),
            },
        });
    });

    items.push({
        title: 'Accent',
        label: 'Accent Color',
        eventKey: 'accent',
        component: SwatchFilter,
        isEnabled: group.mga?.length > 0,
        content: {
            // showDefaultTitle: true,
            // displayShowMoreOption: false,
            // meta: getFilterMetaData(product, 'mga'),
            // warning: getWarning(product, 'mga'),
            hasSwatch: true,
            fieldType: 'mga',
            options: group.mga || [],
            selectedOption: getSelectedOption(group.mga),
        },
    });


    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};

const getCerokoteItems = (product: any, group: any) => {
    const items: ITabs = [
        {
            title: (isActive) => isActive ? <i className="fa-solid fa-circle-xmark tw-text-gray-400"></i> : <i className="fa-solid fa-circle-xmark tw-text-blue-900"></i>,
            label: 'None Tab',
            eventKey: 'none',
            actionPayload: { feature_group: 'N' },
            isEnabled: true,
            component: () => null
        },
        {
            title: 'Color',
            label: 'All cerakote',
            eventKey: 'cerakote',
            component: SwatchFilter,
            isEnabled: group.all_cerakote?.length > 0,
            isSelected: true,
            content: {
                // showDefaultTitle: false,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'all_cerakote'),
                // warning: getWarning(product, 'all_cerakote'),
                hasSwatch: true,
                fieldType: 'all_cerakote',
                options: group.all_cerakote,
                selectedOption: getSelectedOption(group.all_cerakote),
            },
        },
    ];

    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};
