import queryString from 'qs';
import { apiActionTypes } from '../../middleware';
const base = '@@Containers/CustomerContainer/';

export const LOGIN = apiActionTypes(`${base}LOGIN`);
export const EMAIL_RESET = apiActionTypes(`${base}EMAIL_RESET`);
export const PASSWORD_RESET = apiActionTypes(`${base}PASSWORD_RESET`);
export const LOGOUT = apiActionTypes(`${base}LOGOUT`);
export const GET_ALL_ADDRESSES = apiActionTypes(`${base}GET_ALL_ADDRESSES`);
export const GET_ONE_ADDRESS = apiActionTypes(`${base}GET_ONE_ADDRESS`);
export const CREATE_ADDRESS = apiActionTypes(`${base}CREATE_ADDRESS`);
export const UPDATE_ADDRESS = apiActionTypes(`${base}UPDATE_ADDRESS`);
export const DELETE_ADDRESS = apiActionTypes(`${base}DELETE_ADDRESS`);
export const GET_COUNTRIES = apiActionTypes(`${base}GET_COUNTRIES`);
export const GET_STATES = apiActionTypes(`${base}GET_STATES`);
export const RESET_CUSTOMER_DATA = `${base}RESET_CUSTOMER_DATA`;
export const RESET_SELECTED_ADDRESS = `${base}RESET_SELECTED_ADDRESS`;
export const RESET_TRANSACTIONAL_CUSTOMER_DATA = `${base}RESET_TRANSACTIONAL_CUSTOMER_DATA`;
export const RESET_TASKS_CONTACT_NOTES = `${base}RESET_TASKS_CONTACT_NOTES`;
export const GET_ACCOUNT_INFO_ACTION_TYPES = apiActionTypes(`${base}GET_ACCOUNT_INFO_ACTION_TYPES`);
export const UPDATE_ACCOUNT_INFO_ACTION_TYPES = apiActionTypes(`${base}UPDATE_ACCOUNT_INFO_ACTION_TYPES `);
export const GET_ACCOUNT_ORDER_ACTION_TYPES = apiActionTypes(`${base}GET_ACCOUNT_ORDER_ACTION_TYPES`);
export const GET_ONE_ORDER = apiActionTypes(`${base}GET_ONE_ORDER`);
export const UPDATE_ORDER = apiActionTypes(`${base}UPDATE_ORDER`);
export const RESET_ACCOUNT_INFO = apiActionTypes(`${base}RESET_ACCOUNT_INFO`);
export const GET_SHIPPING_DETAILS_ACTION_TYPES = apiActionTypes(`${base}GET_SHIPPING_DETAILS`);
export const CREATE_ORDER_ACTION_TYPES = apiActionTypes(`${base}CREATE_ORDER`);
export const ACCOUNT_CREATE_ORDER_ACTION_TYPES = apiActionTypes(`${base}ACCOUNT_CREATE_ORDER_ACTION_TYPES`);
export const GET_TRANSACTION_HISTORY = apiActionTypes(`${base}GET_TRANSACTION_HISTORY`);
export const GET_TRANSACTIONAL_DATA = apiActionTypes(`${base}GET_TRANSACTIONAL_DATA`);
export const GET_CUSTOMER_DATA = apiActionTypes(`${base}GET_CUSTOMER_DATA`);
export const GET_STORE_LIST = apiActionTypes(`${base}GET_STORE_LIST`);
export const GET_OPEN_TRANSACTIONS = apiActionTypes(`${base}GET_OPEN_TRANSACTIONS`);
export const GET_TRANSACTION_DETAILS = apiActionTypes(`${base}GET_TRANSACTION_DETAILS`);
export const GET_TASKS_CONTACT_NOTES = apiActionTypes(`${base}GET_TASKS_CONTACT_NOTES`);
export const GET_ORDER_HISTORY = apiActionTypes(`${base}GET_ORDER_HISTORY`);
export const STRIPE_SESSION = apiActionTypes(`${base}STRIPE_SESSION`);
export const RESET_STATES = `${base}RESET_STATES`;

export const PAGE_LIMIT = 50;
export const PAGE_LIMIT_OPEN_TRANSACTIONS = 10;

export const toDate = new Date();
export const fromDate = new Date();
export const fromDateInvoices = new Date();
const twoMonthsAgo = toDate.getMonth() - 2;
const sixMonthsAgo = toDate.getMonth() - 6;
fromDate.setMonth(twoMonthsAgo);
fromDateInvoices.setMonth(sixMonthsAgo);
export const dateFormat = { year: 'numeric', month: '2-digit', day: '2-digit' };

export const paramsInvoices = {
    fromDate: fromDateInvoices.toLocaleString('en-US', dateFormat),
    toDate: toDate.toLocaleString('en-US', dateFormat),
    page: 1,
    limit: PAGE_LIMIT_OPEN_TRANSACTIONS,
};
export const paramsPayments = {
    fromDate: fromDate.toLocaleString('en-US', dateFormat),
    toDate: toDate.toLocaleString('en-US', dateFormat),
    page: 1,
    limit: PAGE_LIMIT_OPEN_TRANSACTIONS,
};
export const paramsOrders = {
    fromDate: fromDate.toLocaleString('en-US', dateFormat),
    toDate: toDate.toLocaleString('en-US', dateFormat),
    page: 1,
    limit: PAGE_LIMIT,
};

export const STORE_SUCCESS = 'Store Success';

export const menuLinksParent = [
    {
        name: 'Account Dashboard',
        link: '/customer/account',
    },
    {
        name: STORE_SUCCESS,
        link: '/customer/store-success',
    },
    {
        name: 'Address Book',
        link: '/customer/address',
    },
    {
        name: 'Account Info',
        link: '/customer/account/me/edit',
    },
    {
        name: 'Orders',
        link: '/customer/account/me/transHistory',
        params: `?recordType=orders&${queryString.stringify(paramsOrders)}`,
    },
    {
        name: 'Pay Bills',
        link: '/customer/account/me/openTrans',
        params: `?recordType=invoices&${queryString.stringify(paramsInvoices)}`,
    },
    {
        name: 'Credits',
        link: '/customer/account/me/transHistory',
        params: `?recordType=credits&${queryString.stringify(paramsOrders)}`,
    },
    {
        name: 'Payments',
        link: '/customer/account/me/orderHistory',
        params: `?recordType=payments&${queryString.stringify(paramsPayments)}`,
    },
];

export const menuLinksChild = [
    {
        name: 'Account Dashboard',
        link: '/shop/customer/account',
    },
    {
        name: 'Address Book',
        link: '/shop/customer/address',
    },
    {
        name: 'Account Info',
        link: '/shop/customer/account/me/edit',
    },
];

export const salesOrderStatus = {
    'Pending Approval': 'In Production',
    'Pending Fulfillment': 'In Production',
    Cancelled: 'Canceled',
    'Pending Billing': 'Shipped',
    Closed: 'Shipped',
    'Partially Fulfilled': 'Partially Shipped',
    'Pending Billing/Partially Fulfilled': 'Partially Shipped',
    Billed: 'Shipped',
};

export const translatedSizeOrders = {
    10: '50',
    25: '100',
    50: '10',
    100: '25',
};

export const translatedSizeInvoices = {
    10: '10',
    25: '25',
};

export const translatedSizePayments = {
    10: '10',
    25: '25',
};
