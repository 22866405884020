import SwatchFilter from '../components/SwatchFilter';
import { ITab, ITabs } from '../types/customizer';
import { getFilterMetaData, getSelectedOption, getWarning } from './commonRingUtils';

export const getArrangementItems = (product: any) => {
    const items: ITabs = [
        {
            title: 'Stone Quantity',
            label: 'Stone Quantity',
            eventKey: 'stoneQuality',
            component: SwatchFilter,
            isEnabled: product.stone_quantity?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'stone_quantity'),
                // warning: getWarning(product, 'stone_quantity'),
                hasSwatch: false,
                fieldType: 'stone_quantities',
                options: product.stone_quantity || [],
                selectedOption: getSelectedOption(product.stone_quantity),
            },
        },
        {
            title: 'Carat Weight',
            label: 'Ring Total Carat Weight',
            eventKey: 'caratWeight',
            component: SwatchFilter,
            isEnabled: product.variant?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'variant'),
                // warning: getWarning(product, 'variant'),
                hasSwatch: false,
                fieldType: 'variants',
                options: product.variant || [],
                selectedOption: getSelectedOption(product.variant),
            },
        },
        {
            title: 'Color Arrangement',
            label: 'Color Arrangement',
            eventKey: 'colorArrangement',
            component: SwatchFilter,
            isEnabled: product.color_arrangement?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'color_arrangement'),
                // warning: getWarning(product, 'color_arrangement'),
                hasSwatch: false,
                fieldType: 'color_arrangements',
                options: product.color_arrangement || [],
                selectedOption: getSelectedOption(product.color_arrangement),
            },
        },
    ];
    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};
