/* eslint-disable camelcase */
import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { IDescription, IOption } from '../../types/customizer';
import If from '../../../../components/Shared/If';
import './index.scss';
import URLs from '../../../../utils/url';
import Modal from 'react-modal';
import closeIcon from '../../../../assets/images/close-icon.svg';
import MaterialInfo from '../SwatchCategory/MaterialInfo';
import { customStyles } from '../SwatchCategory';

interface ISwatchOption extends IOption {
    hasSwatch: boolean;
    fieldType: string;
    onChange: (updated: any) => void;
    selectedOption: IOption;
    isChanging: string;
    moreInfo?: IDescription | null;
}   

const SwatchOption = ({ type, name, short_name, swatch_url, selected, sku, additionalClassName, hasSwatch, fieldType, onChange,
    selectedOption, isChanging, moreInfo }: ISwatchOption) => {
    const [open, setOpen] = React.useState(false)
    const handleClick = () => {
        if (!selected) {
            onChange({ [fieldType]: sku });
        } else {
            if(selectedOption.has_inside_reversed_option) {
                if(selectedOption.is_reverse_selected) {
                    onChange({[fieldType]: sku})
                } else {
                    onChange({[fieldType]: sku + 'REV'})
                }
            }
        }
    };

    const onClose = () => {
        setOpen(false)
    }

    const openModal = () => {
        setOpen(true)
    }

    const getUrl = (swatch_url: string) => {
        return swatch_url.indexOf('http') !== -1 ? swatch_url : `${URLs.assetsBaseUrl}${swatch_url}`
    }

    if (hasSwatch) {
        return (
            <div className={clsx({ 'sm:tw-w-28 tw-text-center sm:tw-mr-5 tw-my-2 tw-relative': true, 'selected-border': isChanging.includes(sku) })}>
                <div className="xs:tw-px-1.5 sm:tw-px-2">
                    <div
                        className={clsx('tw-inline-block', { 'tw-border tw-border-dashed tw-border-black tw-rounded-full': selected && !isChanging })}
                        role="presentation"
                        onClick={handleClick}
                    >
                        <div
                            className="tw-block tw-relative xs:tw-w-16 xs:tw-h-16 sm:tw-w-22.5 sm:tw-h-22.5 tw-rounded-full tw-cursor-pointer tw-bg-no-repeat tw-bg-center tw-bg-contain tw-m-1"
                            style={{ backgroundImage: `url(${getUrl(swatch_url)})` }}
                        >
                            {(!isChanging && selected && moreInfo) &&
                                <div onClick={openModal} className='overlay tw-bg-blue-500 xs:tw-text-xs sm:tw-text-sm xs:tw-pt-0.5 sm:tw-pt-1'>
                                    more info
                                </div>
                            }
                        </div>
                    </div>
                    {/* Show only for md devices (768px) */}
                    <div className="tw-text-sm tw-text-blue-900 tw-font-normal tw-mt-1 tw-pl-1 md:tw-block tw-hidden">{name}</div>
                    {/* Show only for less than md devices (768px) */}
                    <div className="tw-text-sm tw-text-blue-900 tw-font-normal tw-mt-1 tw-pl-1 md:tw-hidden tw-block">{short_name || name}</div>
                </div>
                <Modal
                    isOpen={open}
                    onRequestClose={onClose}
                    className="tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-m-auto xs:tw-w-[350px] sm:tw-w-[500px] tw-min-h-[400px] tw-bg-white tw-absolute tw-p-5 group-material-info-modal tw-text-blue-900 secondary-font-family"
                    style={customStyles}
                    overlayClassName="tw-bg-[#999c9fab] tw-fixed tw-inset-0 tw-z-10"
                >
                    <img src={closeIcon} alt="close_icon" className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer" role="presentation" onClick={onClose} />

                    {moreInfo && <MaterialInfo {...moreInfo} />}
                </Modal>
            </div>);
    }
    return (
        <div className={clsx({ 'tw-p-1.5 sm:tw-p-2 tw-cursor-pointer tw-relative': true, 'selected-border-square ': isChanging === sku })}>
            <If condition={name || sku}>
                <div
                    className={twMerge(clsx({
                        'tw-p-1.5  sm:tw-p-2 tw-border tw-border-solid tw-rounded tw-text-center min-w-18 tw-text-sm tw-text-blue-900 tw-font-medium': true,
                        'tw-border-gray-400': !selected || isChanging,
                        'tw-border-dashed tw-border-black': selected && !isChanging,
                    }), `${additionalClassName}`)}
                    role="presentation"
                    onClick={handleClick}
                >
                    {sku === 'CUSTOM' ? sku : name.replace(/(\d+)mm/g, '$1 mm')}
                </div>
            </If>
        </div>
    );
};

export default SwatchOption;
