import React from 'react';
import { getArrangementItems } from './arrangementItems';
import { getFilterMetaData, getSelectedOption, getWarning } from './commonRingUtils';
import SwatchFilter from '../components/SwatchFilter';
import { ITab, ITabs } from '../types/customizer';
import SizeFilter from '../components/SizeFilter';
import Summary from '../components/Summary';
import Data from '../components/Data';
import BoxFilter from '../components/BoxFilter';
import { getCategorizedOptions } from './basic';

export const getV2PageData = (product: any, isDataTabAccess = false) => {
    product.categories = getCategorizedOptions('womens');
    const items: ITabs = [
        {
            title: 'Category', 
            eventKey: 'category',
            component: BoxFilter,
            isEnabled: true,
            content: {
                hasSwatch: true,
                fieldType: 'category',
                options: product.categories,
                selectedOption: getSelectedOption(product.categories),
            },
        },
        {
            title: 'Style',
            label: 'Style',
            eventKey: 'anniversary_style',
            component: SwatchFilter,
            isEnabled: product.style?.length > 0,
            content: {
                // showDefaultTitle: false,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'style'),
                // warning: getWarning(product, 'style'),
                hasSwatch: true,
                fieldType: 'styles',
                options: product.style,
                selectedOption: getSelectedOption(product.style),
            },
        },
        {
            title: 'Material',
            label: 'Ring Material',
            eventKey: 'material',
            component: SwatchFilter,
            isEnabled: product.ring_material?.length > 0,
            content: {
                // showDefaultTitle: false,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'ring_material'),
                // warning: getWarning(product, 'ring_material'),
                hasSwatch: true,
                fieldType: 'ring_materials',
                options: product.ring_material,
                selectedOption: getSelectedOption(product.ring_material),
            },
        },
        {
            title: 'Arrangement',
            eventKey: 'arrangement',
            items: getArrangementItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Gemstone Details',
            label: 'Gemstone Type',
            eventKey: 'gemstone_1',
            component: SwatchFilter,
            isEnabled: product.gemstone?.gemstone_1?.gemstone_material?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_material'),
                // warning: getWarning(product, 'gemstone_material'),
                hasSwatch: true,
                fieldType: 'gemstone_details_1',
                options: product.gemstone?.gemstone_1?.gemstone_material || [],
                selectedOption: getSelectedOption(product.gemstone?.gemstone_1?.gemstone_material),
            },
        },
        {
            title: () => <>2<sup>nd</sup> Gemstone Details</>,
            label: 'Second Gemstone Type',
            eventKey: 'gemstone_2',
            component: SwatchFilter,
            isEnabled: product.gemstone?.gemstone_2?.gemstone_material?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_material'),
                // warning: getWarning(product, 'gemstone_material'),
                hasSwatch: true,
                fieldType: 'gemstone_details_2',
                options: product.gemstone?.gemstone_2?.gemstone_material,
                selectedOption: getSelectedOption(product.gemstone?.gemstone_2?.gemstone_material),
            },
        },
        {
            title: () => <>3<sup>rd</sup> Gemstone Details</>,
            eventKey: 'gemstone_3',
            label: 'Third Gemstone Type',
            component: SwatchFilter,
            isEnabled: product.gemstone?.gemstone_3?.gemstone_material?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_material'),
                // warning: getWarning(product, 'gemstone_material'),
                hasSwatch: true,
                fieldType: 'gemstone_details_3',
                options: product.gemstone?.gemstone_3?.gemstone_material,
                selectedOption: getSelectedOption(product.gemstone?.gemstone_3?.gemstone_material),
            },
        },
        {
            title: 'Size',
            label: 'Ring Size',
            eventKey: 'ringSize',
            component: SizeFilter,
            isEnabled: product.size?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'size'),
                // warning: getWarning(product, 'size'),
                hasSwatch: false,
                fieldType: 'size',
                options: product.size || [],
                selectedOption: getSelectedOption(product.size),
            },
        },
        {
            title: 'Summary',
            eventKey: 'summary',
            component: Summary,
            isEnabled: true
        },
        {
            title: 'Data',
            eventKey: 'data',
            component: Data,
            isEnabled: isDataTabAccess,
        },
    ];

    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};
