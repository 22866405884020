import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectCustomer = state => get(state, 'customer');

export const makeSelectIsFetching = () =>
    createSelector([selectCustomer], isFetching => get(isFetching, 'isFetching', {}));

export const makeSelectAddresses = () => createSelector([selectCustomer], addresses => get(addresses, 'addresses', {}));

export const makeSelectSelectedAddress = () =>
    createSelector([selectCustomer], selectedAddress => get(selectedAddress, 'selectedAddress', {}));

export const makeSelectCountries = () => createSelector([selectCustomer], countries => get(countries, 'countries', {}));

export const makeSelectStates = () => createSelector([selectCustomer], states => get(states, 'states', {}));

export const makeSelectAccountInfo = () =>
    createSelector([selectCustomer], accountInfo => get(accountInfo, 'accountInfo', {}));

export const makeSelectOrderData = () => createSelector([selectCustomer], orderData => get(orderData, 'orderData', {}));

export const makeSelectShipping = () =>
    createSelector([selectCustomer], shippingMethods => get(shippingMethods, 'shippingMethods', {}));

export const makeSelectProducts = () =>
    createSelector([selectCustomer], customerProducts => get(customerProducts, 'customerProducts', {}));

export const makeSelectSummary = () => createSelector([selectCustomer], summary => get(summary, 'summary', {}));
export const makeSelectOrder = () => createSelector([selectCustomer], order => get(order, 'order', {}));
export const makeSelectTransactionHistory = () => createSelector([selectCustomer], transactionHistory => get(transactionHistory, 'transactionHistory', {}));
export const makeSelectOpenTransactions = () => createSelector([selectCustomer], openTransactions => get(openTransactions, 'openTransactions', {}));
export const makeSelectOrderHistory = () => createSelector([selectCustomer], orderHistory => get(orderHistory, 'orderHistory', {}));
export const makeSelectTransactionDetails = () => createSelector([selectCustomer], transactionDetails => get(transactionDetails, 'transactionDetails', {}));

export const makeSelectTransactionalData = () =>
    createSelector([selectCustomer], transactionalData => get(transactionalData, 'transactionalData', {}));

export const makeSelectCustomerData = () => createSelector([selectCustomer], customerData => get(customerData, 'customerData', {}));

export const makeSelectIsFetchingTransactionalData = () =>
    createSelector([selectCustomer], isFetchingTransactionalData => get(isFetchingTransactionalData, 'isFetchingTransactionalData', {}));

export const makeSelectIsFetchingCustomerData = () =>
    createSelector([selectCustomer], isFetchingCustomerData => get(isFetchingCustomerData, 'isFetchingCustomerData', {}));

export const makeSelectStoreList = () => createSelector([selectCustomer], storeList => get(storeList, 'storeList', []));

export const makeSelectIsFetchingStoreList = () =>
    createSelector([selectCustomer], isFetchingStoreList => get(isFetchingStoreList, 'isFetchingStoreList', false));
export const makeSelectIsFetchingTaskContactNotes = () =>
    createSelector([selectCustomer], isFetchingTaskContactNotes => get(isFetchingTaskContactNotes, 'isFetchingTaskContactNotes', false));
export const makeSelectTaskContactNotes = () =>
    createSelector([selectCustomer], taskContactNotes => get(taskContactNotes, 'taskContactNotes', {}));
